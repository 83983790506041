let BASEURL = 'https://leasing.pro360dev.freemyip.com'
export const endPoint = {
    // Lease Aggrement
    CREATE_LEASE_AGRE: `${BASEURL}/api/SaleOrder/Create`,
    UPDATE_LEASE_AGRE: `${BASEURL}/api/SaleOrder/Update`,
    GET_LEASE_AGRE: `${BASEURL}/api/LeaseAgreement/Get`,
    DELETE_LEASE_AGRE: `${BASEURL}/api/SaleOrder/Get`,
    // DropDown
    // GET_PROPERT_DROP: `${BASEURL}/api/LeaseAgreement/GetDropDown`,
    GET_PROPERT_DROP: `${BASEURL}/api/LeaseAgreement/GetLeaseCreateDdls`,

}