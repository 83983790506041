import React, { useEffect, useState, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';

const ApprovalUserGrid = ({ fetchApprovalData, rowData }) => {
    const [colDefs, setColDefs] = useState([
        { headerName: "Doc Type", field: "docType", filter: true, floatingfilter: true, minWidth: 400 },
        { headerName: "User No", field: "userNo", filter: true, floatingfilter: true, minWidth: 400 },
        { headerName: "User Level", field: "userLevel", filter: true, floatingfilter: true, minWidth: 400 },
        { headerName: "Department", field: "department", filter: true, floatingfilter: true, minWidth: 400 },
    ]);

    return (
        <div className="ag-theme-quartz" style={{ height: 300 }}>
            <AgGridReact
                getRowStyle={(params) => {
                    if (params.node.rowIndex % 2 === 0) {
                        return { background: '#F0EDF5' };
                    } else {
                        return { background: '#fff' };
                    }
                }}
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={{
                    filter: true,
                    floatingfilter: true,
                    minWidth: 100,
                }}
                pagination={false}
            />
        </div>
    );
};
export default ApprovalUserGrid;
