import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "../INVENTORY/Assets/styles/addmenu.css"
import "../INVENTORY/Assets/styles/style.css"
import "../SalesTrading/Assets/Style/style.css"
import { getRole } from '../Redux/selecters/index'
import PlainLayout from '../POS/component/pages/SideBar/plainLayout';
// import Login from '../POS/component/pages/user/Login';
import Signup from '../POS/component/pages/user/signup';
import DashBoard from '../INVENTORY/Pages/dashBoard/dashboard';
import SaleOrder from '../SalesTrading/Pages/salesOrder/salesOrder';
import CreateSaleOrder from '../SalesTrading/Pages/salesOrder/createSaleOrder';
import { CurrencyProvider } from '../INVENTORY/Layouts/currencyContext';
import DRNListing from '../SalesTrading/Pages/DRN/DRNList';
import CreateDRN from '../SalesTrading/Pages/DRN/createDRN';
import CustomerInvoiceListing from '../SalesTrading/Pages/customerInvoice/customerInvoice';
import CreateCustomerInv from '../SalesTrading/Pages/customerInvoice/createCustomerInv';
import QuotationListing from '../SalesTrading/Pages/Quotation/quotationList';
import CreateQuotation from '../SalesTrading/Pages/Quotation/createQuotation';
import MainSalesLayout from '../SalesTrading/Layouts/mainLayout';
import QuotationView from '../SalesTrading/Pages/Quotation/quotationView';
import SalesInvoicePrint from '../SalesTrading/Pages/customerInvoice/print';
import SalesReturn from '../SalesTrading/Pages/salesReturn/salesReturn';
import SaleReturnCreate from '../SalesTrading/Pages/salesReturn/saleReturnCreate';
import QuotationPrint from '../SalesTrading/Pages/Quotation/print';
import SaleOrderPrint from '../SalesTrading/Pages/salesOrder/print';
import DNPrint from '../SalesTrading/Pages/DRN/print';
import SaleOrderView from '../SalesTrading/Pages/salesOrder/view';
import DnView from '../SalesTrading/Pages/DRN/view';
import CustomerInvView from '../SalesTrading/Pages/customerInvoice/invoiceview';
import Posting from '../SalesTrading/Pages/posting/posting';
import InventoryLogin from '../INVENTORY/Components/login/login';
import QuotationRevision from '../SalesTrading/Pages/Quotation/revision';
import SaleReturnPrint from '../SalesTrading/Pages/salesReturn/returnPrint';
import MainPMLayout from '../PropertyManagement/Layouts/mainLayout';
import PropertyDashBoard from '../PropertyManagement/Pages/dashboard/dashBoard';
import LeaseListing from '../PropertyManagement/Pages/leaseAgreement/leaseListing';
import ApprovalListing from '../SalesTrading/Pages/approvalUser/approvalUserList';
import ApprovalUser from '../SalesTrading/Pages/approvalUser/approvalUser';
import EditApprovalUser from '../SalesTrading/Pages/approvalUser/editApprovalUser';
import ApprovalUserView from '../SalesTrading/Pages/approvalUser/approvalUserView';
import Approval from '../SalesTrading/Pages/approval/approval';

const AppRoutes = () => {
  const role = useSelector(getRole);
  const [userRole, setUserRole] = useState(role);
  useEffect(() => {
    // navigate('/')
  }, [userRole]);
  return (
    <CurrencyProvider>
      <Routes>
        {!userRole && <>
          {/* Customer routes */}
          <Route path="/login" element={<PlainLayout><InventoryLogin /></PlainLayout>} />
          <Route path="/signup" element={<PlainLayout><Signup /></PlainLayout>} />
        </>
        }
        <Route path="/" element={<MainSalesLayout><DashBoard /></MainSalesLayout>} />
        <>
          {/* Sales Routes */}
          <Route path="/Sales/Dashboard" element={<MainSalesLayout><DashBoard /></MainSalesLayout>} />
          <Route path="/Sales/SaleOrder" element={<MainSalesLayout><SaleOrder /></MainSalesLayout>} />
          <Route path="/Sales/SaleOrder/create" element={<MainSalesLayout><CreateSaleOrder /></MainSalesLayout>} />
          <Route path="/Sales/SaleOrder/Print" element={<MainSalesLayout><SaleOrderPrint /></MainSalesLayout>} />
          <Route path="/Sales/SaleOrder/:id/:id" element={<MainSalesLayout><SaleOrderView /></MainSalesLayout>} />
          <Route path="/Sales/DRN" element={<MainSalesLayout><DRNListing /></MainSalesLayout>} />
          <Route path="/Sales/DRN/create" element={<MainSalesLayout><CreateDRN /></MainSalesLayout>} />
          <Route path="/Sales/DRN/Print" element={<MainSalesLayout><DNPrint /></MainSalesLayout>} />
          <Route path="/Sales/DRN/:id/:id" element={<MainSalesLayout><DnView /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice" element={<MainSalesLayout><CustomerInvoiceListing /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice/Create" element={<MainSalesLayout><CreateCustomerInv /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice/Print" element={<MainSalesLayout><SalesInvoicePrint /></MainSalesLayout>} />
          <Route path="/Sales/Customer/Invoice/:id/:id" element={<MainSalesLayout><CustomerInvView /></MainSalesLayout>} />
          <Route path="/Sales/Quotation" element={<MainSalesLayout><QuotationListing /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/Create" element={<MainSalesLayout><CreateQuotation /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/:id/:id" element={<MainSalesLayout><QuotationView /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/Print" element={<MainSalesLayout><QuotationPrint /></MainSalesLayout>} />
          <Route path="/Sales/Quotation/Revision/:id/:id" element={<MainSalesLayout><QuotationRevision /></MainSalesLayout>} />
          <Route path="/Sales/Return" element={<MainSalesLayout><SalesReturn /></MainSalesLayout>} />
          <Route path="/Sales/Return/Create" element={<MainSalesLayout><SaleReturnCreate /></MainSalesLayout>} />
          <Route path="/Sales/Return/:id/:id" element={<MainSalesLayout><SaleReturnPrint /></MainSalesLayout>} />
          <Route path="/Sales/Posting" element={<MainSalesLayout><Posting /></MainSalesLayout>} />
          <Route path="/Sales/Approval/Document" element={<MainSalesLayout><Approval /></MainSalesLayout>} />
          <Route path="/Sales/ApprovalUser/List" element={<MainSalesLayout><ApprovalListing /></MainSalesLayout>} />
          <Route path="/Sales/ApprovalUser/Create" element={<MainSalesLayout><ApprovalUser /></MainSalesLayout>} />
          <Route path="/Sales/ApprovalUser/:id" element={<MainSalesLayout><EditApprovalUser /></MainSalesLayout>} />
          <Route path="/Sales/ApprovalUser/:id/:id" element={<MainSalesLayout><ApprovalUserView /></MainSalesLayout>} />
          {/* Property Management */}
          <Route path="/Property/Management/Dashboard" element={<MainPMLayout><PropertyDashBoard /></MainPMLayout>} />
          <Route path="/Property/Management/LeaseAgreement" element={<MainPMLayout><LeaseListing /></MainPMLayout>} />

        </>
      </Routes>
    </CurrencyProvider >
  );
};

export default AppRoutes;