import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import InvApiService from '../../../INVENTORY/services/apiService';
import ApiService from '../../services/apiServices';
import Select from 'react-select';
import ApprovalUserGrid from './grid';
import "./approval.css"
import { FaAngleLeft } from "react-icons/fa6";
const ApprovalUserView = () => {
    const location = useLocation();
    const [Active, setActive] = useState(false);
    const [DelegateActive, setDelegateActive] = useState(false);
    const [AutoAprove, setAutoAprove] = useState(false);
    const [SupplierTab, setSupplierTab] = useState(true);
    const [POTab, setPOTab] = useState(true);
    const [AppDetail, setAppDetail] = useState(true);
    const invApiService = new InvApiService()
    const apiService = new ApiService()
    const [rowData, setRowData] = useState([]);
    const [formData, setFormData] = useState({
        DocType: [],
        UserNo: [],
        UserRole: [],
        UserEmail: "",
        UserLevel: "",
        recordId: "",
        Signature: "",
        amountto: '',
        amountFrom: '',
        delegateto: '',
        date: '',
        Department: [],
    })
    const item = location.state;
    useEffect(() => {
        fetchApprovalData();
    }, []);
    useEffect(() => {
        const item = location.state;
        const fetchData = async () => {
            const Users = await invApiService.get("GET_USERS_LIST");
            const selectedUser = Users?.data?.result.find(user => user.user_Name === item?.userNo)
            setFormData({
                recordId: item?.rcid || "",
                UserLevel: item?.userLevel || "",
                UserNo: { label: item?.userNo, value: item?.userNo },
                Department: { label: item?.department, value: item?.department },
                Signature: item?.sigFilePath,
                DocType: { label: item?.docType, value: item?.docType },
                UserEmail: selectedUser?.emailId,
                UserRole: { label: selectedUser?.role, value: selectedUser?.role }
            });
            setActive(item?.active === 1 ? true : false)
            setAutoAprove(item?.isAutoApprove === 1 ? true : false)
        }
        if (item) {
            fetchData()
        }
    }, [location?.state]);
    const fetchApprovalData = async () => {
        try {
            const response = await apiService.get('GET_APPROVAL_USER');
            const approvals = response.data.result?.filter(dep => dep.docType === item?.docType)
            setRowData(approvals);
        } catch (error) {
            console.error("Error fetching packing data:", error);
        }
    }
    const handleBackClick = () => {
        window.history.back();
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Approval User</h5>
                <div className='d-flex gap-3'>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Users Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Name</label>
                                <Select
                                    value={formData.UserNo} isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Role</label>
                                <Select
                                    value={formData.UserRole} isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Email</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="UserEmail" disabled
                                    value={formData?.UserEmail}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex justify-content-between ">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">Signature</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="Signature"
                                    value={formData?.Signature}
                                    required disabled
                                />
                            </div>
                            <div className="col-md-4 d-flex justify-content-between gap-3">
                                <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="Active" style={{ width: "20px", height: "20px" }}
                                        name="Active" disabled
                                        checked={Active}
                                    />
                                    <label className='px-2'>Active</label>
                                </div>
                                <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="AutoAprove" style={{ width: "20px", height: "20px" }}
                                        name="AutoAprove" disabled
                                        checked={AutoAprove}
                                    />
                                    <label className='px-2'>Auto Aprove</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setAppDetail(!AppDetail)}>
                    <div className='h6 pt-1'>Approval Details</div>
                </div>
                <div className={`slide-content ${AppDetail ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${AppDetail ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3  d-flex justify-content-between">
                            <div className="col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Doc Type</label>
                                <Select
                                    value={formData?.DocType} isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Level</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="UserLevel"
                                    value={formData?.UserLevel} disabled
                                />
                            </div>
                            <div className="col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Department</label>
                                <Select
                                    value={formData.Department} isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row p-1 d-flex justify-content-between">
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="amountFrom" className="form-label  pt-2 start">Amount From</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="amountFrom"
                                    name="amountFrom"
                                    value={formData?.amountFrom} disabled
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="amountto" className="form-label  pt-2 start">Amount To</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="amountto"
                                    name="amountto"
                                    value={formData?.amountto} disabled
                                />
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <div className="form-group row p-0 m-0" >
                            <div className="col-8 row p-3 bgbox1" >
                                <div className="col-6 d-flex justify-content-between ">
                                    <label htmlFor="delegateto" className="form-label  pt-2 start">Delegate To</label>
                                    <input
                                        type="number"
                                        className="form-Input w-70"
                                        id="delegateto"
                                        name="delegateto"
                                        value={formData?.delegateto} disabled
                                    />
                                </div>
                                <div className="col-6 d-flex justify-content-between ">
                                    <div className="d-flex justify-content-between ">
                                        <label htmlFor="amountto" className="form-label  pt-2 start">Until This Date</label>
                                        <input
                                            type="date"
                                            className="form-Input w-70"
                                            id="date"
                                            name="date"
                                            value={formData?.date} disabled
                                        />
                                    </div>
                                    <div className='gap-2 center '>
                                        <input className="form-check-input"
                                            type="checkbox"
                                            id="Active" style={{ width: "20px", height: "20px" }}
                                            name="Active"
                                            checked={DelegateActive} disabled
                                        />
                                        <label className='px-2'>Active</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Approval User List</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 ${SupplierTab ? 'slide-in' : 'slide-out'}`}>
                        <ApprovalUserGrid rowData={rowData} fetchApprovalData={fetchApprovalData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalUserView;