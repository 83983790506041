import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import InvApiService from '../../../INVENTORY/services/apiService';
import ApiService from '../../services/apiServices';
import Select from 'react-select';
import ApprovalUserGrid from './grid';
import { toast } from 'react-toastify';
import "./approval.css"
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { getUserName } from '../../../Redux/selecters';
import { ClipLoader } from 'react-spinners';
const ApprovalUser = () => {
    let navigate = useNavigate();
    const [Department, setDepartment] = useState([]);
    const [Active, setActive] = useState(false);
    const [DelegateActive, setDelegateActive] = useState(false);
    const [Users, serUsers] = useState([]);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const invApiService = new InvApiService()
    const apiService = new ApiService()
    const userName = useSelector(getUserName);
    const [rowData, setRowData] = useState([]);
    const [approvalList, setApprovalList] = useState([]);
    const [loader, setLoader] = useState(false);

    const [formData, setFormData] = useState({
        DocType: [],
        UserNo: [],
        UserRole: [],
        UserEmail: "",
        UserLevel: "",
        recordId: "",
        Signature: "",
        amountto: '',
        amountFrom: '0',
        delegateto: '',
        date: '',
        Department: [],
    })
    const DocType = [
        { value: 'SQuotation', label: 'Quotation' },
        { value: 'SOrder', label: 'Sale Order' },
        { value: 'Delivery Note', label: 'Delivery Note' },
        { value: 'DN Invoice', label: 'Invoice' },
        { value: 'Sale Return', label: 'Sales Return' },
    ]
    useEffect(() => {
        fetchData()
        fetchApprovalData();
    }, []);
    const handleActiveSelection = (e) => {
        setActive(e);
    }
    const handleDeleActiveSelection = (e) => {
        setDelegateActive(e);
    }
    const fetchData = async () => {
        const department = await invApiService.get("GET_DEPARTMENT_MASTER");
        setDepartment(department?.data?.result);
        const UsersLis = await invApiService.get("GET_USERS_LIST");
        serUsers(UsersLis?.data?.result);
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const fetchApprovalData = async () => {
        try {
            const params = new URLSearchParams();
            const response = await apiService.get('GET_APPROVAL_USER');
            const approvals = response.data.result?.filter(dep => dep.active === 1)
            setRowData(approvals);
            setApprovalList(approvals)
        } catch (error) {
            console.error("Error fetching packing data:", error);
        }
    }
    const handleUser = async () => {
        try {
            setLoader(true)
            if (!formData?.UserNo?.label) {
                toast.warning("Select User to create Approval");
                setLoader(false)
                return;
            }
            if (!formData?.DocType?.value) {
                toast.warning("Select Document Type");
                setLoader(false)
                return;
            }
            if (!formData?.UserLevel) {
                toast.warning("Enter User level for Approval");
                setLoader(false)
                return;
            }
            debugger
            if (!formData?.UserLevel === "0") {
                toast.warning("User Level Must be Greater Then 0");
                setLoader(false)
                return;
            }
            const currentDatetimeUTC = new Date().toISOString();
            let body = {
                docType: formData?.DocType?.value,
                userNo: formData?.UserNo?.label,
                userLevel: formData?.UserLevel,
                department: formData?.Department?.label,
                isAutoApprove: 0,
                active: Active === true ? 1 : 0 || 1,
                sigFilePath: formData?.Signature,
                createdBy: userName,
                stockTypeName: "string",
                fromPOAmount: formData?.amountFrom || 0,
                toPOAmount: formData?.amountto || 0,
                delegateTo: formData?.delegateto,
                delegateUntil: formData.date || currentDatetimeUTC.toString(),
                isDelegateUserActive: DelegateActive
            }
            const response = await apiService.post('CREATE_APPROVAL_USER', body);
            if (response?.data?.statusCode === 201) {
                setLoader(false)
                toast.success(response.data.message)
                navigate('/Sales/ApprovalUser/List');
            } else {
                setLoader(false)
                toast.warning(response.data.message)
            }
        } catch (error) {
            setLoader(false)
            toast.warning("Something may be wrong try again later!")
        }
    }
    const handleDocTypeChange = (selectedOption) => {
        setFormData({ ...formData, DocType: selectedOption });
        const approvals = approvalList?.filter(dep => dep.docType === selectedOption?.value)
        setRowData(approvals)
    }
    const handleDepartmentChange = (selectedOption) => {
        setFormData({ ...formData, Department: selectedOption });
    }
    const handleUsersDrop = (selectedOption) => {
        setFormData({
            ...formData,
            UserNo: selectedOption,
            UserEmail: selectedOption?.value.emailId,
            UserRole: { label: selectedOption?.value?.role, value: selectedOption?.value?.role },
            Department: { label: selectedOption?.value?.department, value: selectedOption?.value?.department },
        });
    }
    const handleBackClick = () => {
        window.history.back();
    };
    const handleReset = () => {
        setFormData({
            DocType: [],
            UserNo: [],
            UserLevel: "",
            recordId: "",
            Signature: "",
            Department: [],
        });
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Approval User</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' type="submit" onClick={() => handleUser()}><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    }
                    <button className='mainbtnRed' onClick={handleReset}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Users Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Name</label>
                                <Select
                                    value={formData.UserNo}
                                    onChange={handleUsersDrop}
                                    options={Users?.map((sup) => ({ value: sup, label: sup?.user_Name }))}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Role</label>
                                <Select
                                    value={formData.UserRole} isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Email</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="UserEmail" disabled
                                    value={formData?.UserEmail}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex justify-content-between ">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">Signature</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="Signature"
                                    value={formData?.Signature}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4 d-flex justify-content-between gap-3">
                                <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="Active" style={{ width: "20px", height: "20px" }}
                                        name="Active"
                                        checked={Active}
                                        onChange={(e) => handleActiveSelection(e.target.checked)}
                                    />
                                    <label className='px-2'>Active</label>
                                </div>
                                {/* <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="AutoAprove" style={{ width: "20px", height: "20px" }}
                                        name="AutoAprove"
                                        checked={AutoAprove}
                                        onChange={(e) => handleAutoApprove(e.target.checked)}
                                    />
                                    <label className='px-2'>Auto Aprove</label>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Approval Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3  d-flex justify-content-between">
                            <div className="col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Doc Type</label>
                                <Select
                                    value={formData?.DocType}
                                    options={DocType?.map((doc) => ({ value: doc?.value, label: doc?.label }))}
                                    onChange={handleDocTypeChange}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Level</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="UserLevel"
                                    min={1}
                                    value={formData?.UserLevel}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-lg-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Department</label>
                                <Select
                                    value={formData.Department}
                                    onChange={handleDepartmentChange}
                                    options={Department?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row p-1 d-flex justify-content-between">
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="amountFrom" className="form-label  pt-2 start">Amount From</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="amountFrom"
                                    name="amountFrom"
                                    value={formData?.amountFrom}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="amountto" className="form-label  pt-2 start">Amount To</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="amountto"
                                    name="amountto"
                                    value={formData?.amountto}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <div className="form-group row p-0 m-0" >
                            <div className="col-8 row p-3 bgbox1" >
                                <div className="col-6 d-flex justify-content-between ">
                                    <label htmlFor="delegateto" className="form-label  pt-2 start">Delegate To</label>

                                    <Select
                                        isDisabled={DelegateActive === true ? false : true}
                                        value={formData.delegateto}
                                        onChange={handleUsersDrop}
                                        options={Users?.map((sup) => ({ value: sup, label: sup?.user_Name }))}
                                        className="w-70 p-0"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            padding: 9
                                        })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                                color: state.isFocused ? '#000 ' : '#000',
                                                ':hover': {
                                                    backgroundColor: '#F5F5F5',
                                                    color: '#000 ',
                                                },
                                            }),
                                        }}
                                    />
                                </div>
                                <div className="col-6 d-flex justify-content-between ">
                                    <div className="d-flex justify-content-between ">
                                        <label htmlFor="amountto" className="form-label  pt-2 start">Until This Date</label>
                                        <input
                                            type="date"
                                            className="form-Input w-70"
                                            id="date"
                                            name="date"
                                            disabled={DelegateActive === true ? false : true}
                                            value={formData?.date}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className='gap-2 center '>
                                        <input className="form-check-input"
                                            type="checkbox"
                                            id="Active" style={{ width: "20px", height: "20px" }}
                                            name="Active"
                                            checked={DelegateActive}
                                            onChange={(e) => handleDeleActiveSelection(e.target.checked)}
                                        />
                                        <label className='px-2'>Active</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Approval User List</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 ${SupplierTab ? 'slide-in' : 'slide-out'}`}>
                        <ApprovalUserGrid rowData={rowData} fetchApprovalData={fetchApprovalData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalUser;
