import React, { useState, useEffect, Component } from 'react'
import ApiService from "../../services/apiServices"
import { AiOutlinePrinter } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import { FaRegEye } from "react-icons/fa6"
import moment from 'moment'
import { GrEdit } from "react-icons/gr";
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../../../INVENTORY/Pages/pagination/pagination'
const SaleOrder = () => {
    const [loading, setLoading] = useState(false);
    const [soData, setSOData] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [QoPagination, setQOPagination] = useState();
    const [QoData, setQotData] = useState([]);

    const currentDatetimeUTC = new Date().toISOString();
    const apiService = new ApiService();
    let navigate = useNavigate();
    useEffect(() => {
        getSO();
        getQuot();
    }, []);
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY") || " ";
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const handlePO = (data) => {
        navigate("/Sales/SaleOrder/create", { state: data })
    }
    const getSO = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            // params.append('PageNo', newPageNo || 1);
            // params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_SALE', `?${params.toString()}`);
            setPagination(res.data.paginationData)
            const sortedData = res?.data?.result.slice().sort((a, b) => {
                const idA = parseInt(a.sOrderNo.split('-')[2]);
                const idB = parseInt(b.sOrderNo.split('-')[2]);
                return idB - idA;
            });
            setSOData(sortedData || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    }
    const getQuot = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            // params.append('PageNo', newPageNo || 1);
            // params.append('PageSize', pageSize || 20);
            params.append('Status', 1);
            const res = await apiService.getApiParamater('GET_QUOT', `?${params.toString()}`);
            setQOPagination(res.data.paginationData)
            const sortedData = res?.data?.result.slice().sort((a, b) => {
                const idA = parseInt(a.quotationNo.split('-')[2]);
                const idB = parseInt(b.quotationNo.split('-')[2]);
                return idB - idA;
            });
            setQotData(sortedData || []);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    }
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/Sales/SaleOrder/Print`, { state: selectedRow })
        }
    }
    const handleEdit = (clickedRowData) => {
        if (clickedRowData || selectedRow) {
            let eye = false
            if (clickedRowData) {
                const updatedData = { ...clickedRowData, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
                    navigate(`/Sales/SaleOrder/${clickedRowData?.sOrderNo}`, { state: updatedData });
                } else {
                    navigate(`/inventory/Purchase/${updatedData.recordId}`, { state: updatedData })
                }
            } else {
                const updatedData = { ...selectedRow, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
                    navigate(`/Sales/SaleOrder/${selectedRow?.sOrderNo}`, { state: updatedData });
                } else {
                    navigate(`/inventory/Purchase/${updatedData.recordId}`, { state: updatedData })
                }
            }
        }
    };
    const handleEye = () => {
        if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate(`/Sales/SaleOrder/${selectedRow?.sOrderNo}`, { state: updatedData });
        };
    };
    const SaleData = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'SO No', field: 'sOrderNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        {
            headerName: 'Qot No', field: 'quotationNo', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
        },
        {
            headerName: 'Date', field: 'docDate', filter: true, floatingfilter: true, suppressMovable: true, width: 210,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Customer ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 180 },
        { headerName: 'Customer Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 210 },
        {
            headerName: 'Delivery Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
        {
            headerName: 'Approval date', field: 'modifiedOn', filter: true, floatingfilter: true, suppressMovable: true, width: 160,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">Pending</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Approved</button>);
                }
                else if (params.value === 5 || params.value === null) {
                    return (<button class="btn hold">Hold</button>);
                }
                else if (params.value === 2 || params.value === null) {
                    return (<button class="btn reject">Rejected</button>);
                } else {
                    return params.value;
                }
            }
        },
    ];
    const PendingSales = [
        { headerName: 'Qot No', field: 'quotationNo', filter: true, floatingfilter: true, suppressMovable: true, width: 335 },
        {
            headerName: 'Date', field: 'quotationDate', filter: true, floatingfilter: true, suppressMovable: true, width: 330,
            valueFormatter: dateFormatter,
            comparator: dateComparator,
        },
        { headerName: 'Customer ID', field: 'vendorId', filter: true, floatingfilter: true, suppressMovable: true, width: 350 },
        { headerName: 'Customer Name', field: 'vendorName', filter: true, floatingfilter: true, suppressMovable: true, width: 350 },
        {
            headerName: 'Sale Order', field: 'id', cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                handlePO: handlePO,
                clicked: function (field, isActive) {
                    // updateActiveStatus(field, isActive);
                },
            },
        }
    ];
    const frameworkComponents = {
        btnCellRenderer: BtnCellRenderer,
    };
    const handlenew = () => {
        navigate('/Sales/SaleOrder/create')
    }
    const onCellClicked = (event) => {
        if (event.colDef.field === 'sOrderNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEdit(clickedRowData)
        }
    };
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    }
    return (
        <div className='p-4'>
            <div className='border-box2 p-4'>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <div className=' justify-content-between d-flex align-items-center pb-3'>
                            <h4 className='col mainInvHead'>
                                Pending Sales Orders
                            </h4>
                            <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                <IoAddSharp color='#fff' className='center mt-1' /> <div>Add New</div>
                            </button>
                        </div>
                        <div className='gridshad'>
                            <div className="ag-theme-quartz" style={{ height: 600 }}>
                                <AgGridReact
                                    getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                                    rowData={QoData}
                                    columnDefs={PendingSales}
                                    frameworkComponents={{ frameworkComponents }}
                                />
                            </div>
                            <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={QoPagination} getItems={getQuot} />
                        </div>
                    </>
                )}
                <div>
                    <div className='justify-content-between d-flex  pt-5'>
                        <h5 className='col mainInvHead'>Sales Orders</h5>
                        <div className='d-flex justify-content-end gap-3'>
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>View</div>
                            </div>
                            <div
                                onClick={selectedRow && selectedRow.status === 1 ? null : handleEdit}
                                className={`mainbtnSecondary flex-end gap-2 ${!selectedRow || selectedRow.status === 1 ? 'disabled' : ''
                                    }`}
                                style={{ cursor: selectedRow && selectedRow.status === 1 ? 'not-allowed' : 'pointer' }}
                            >
                                <GrEdit color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Edit</div>
                            </div>
                            <div
                                onClick={handlePrint}
                                className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <AiOutlinePrinter color='#fff' className='center mt-1 mx-2' />
                                <div className='center'>Print</div>
                            </div>
                        </div>
                    </div>
                    <div className='gridshad mt-3'>
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    if (params.node.rowIndex % 2 === 0) {
                                        return { background: '#F0EDF5' };
                                    } else {
                                        return { background: '#fff' };
                                    }
                                }}
                                rowData={soData}
                                enableFilter={true}
                                floatingFilter={true}
                                columnDefs={SaleData}
                                onSelectionChanged={onSelectionChanged}
                                onCellClicked={onCellClicked}
                            />
                        </div>
                        {/* <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} /> */}
                        <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} />
                    </div>
                </div>
            </div>
        </div>
    );
};
class BtnCellRenderer extends Component {
    btnClickedHandler() {
        const { value, clicked } = this.props;
        clicked(value);
    }
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.btnEditClickedHandler = this.btnEditClickedHandler.bind(this);
    }

    btnEditClickedHandler() {
        const { data, handlePO } = this.props;

        handlePO(data);
    }
    render() {
        return (
            <div>
                <button onClick={this.btnEditClickedHandler} className="gridbtn p-0">Create SO</button>
            </div>
        )
    }
}
export default SaleOrder; 