import React, { useEffect, useState, useRef } from 'react';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import ItemsGrid from './itemGrid';
import AsyncSelect from 'react-select/async';
import { useLocation } from 'react-router-dom';
import QuotItemsView from './viewItems';
import moment from 'moment';
const QuotationView = () => {
    const [loader, setLoader] = useState(false);
    const fileInputRef = useRef(null);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [ItemsData, setQuotationData] = useState([]);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [activeTab, setActiveTab] = useState(0);
    const [attachmentFile, setAttachmentFile] = useState(null);
    const [CustomerTab, setCustomerTab] = useState(false);
    const [QotNum, setQotNum] = useState();
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [Payment, setPayment] = useState([]);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const location = useLocation();
    const itemData = location.state;
    
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    useEffect(() => {
        const item = location.state;
        const getDocId = async () => {
            try {
                setQotNum(item?.quotationNo)
                setIsVatExcluded(item?.vatInclusive === false ? true : false)
                setPayment({ label: item?.paymentTerms, value: item?.paymentTerms })
                setSelectedCurency({ label: item?.currencyCode, value: item?.currencyCode })
                setFormData({
                    DocumentDate: item?.quotationDate,
                    deliveryDate: item?.deliveryDate,
                    quotationNo: item?.quotationNo,
                    SellerName: item?.sellerName,
                    ContactNo: item?.contatNo,
                    deliveryPoint: item?.deliveryPoint,
                    currencyCode: item?.currencyCode,
                    location: item?.location,
                    contactPerson: item?.contactPerson,
                    remarks: item?.remarks,
                    SupplierContact: item?.contractNo,
                    costCenter1: { label: item?.costCenter1, value: item?.costCenter1 },
                    costCenter2: { label: item?.costCenter2, value: item?.costCenter2 },
                    costCenter3: { label: item?.costCenter3, value: item?.costCenter3 },
                    costCenter4: { label: item?.costCenter4, value: item?.costCenter4 },
                    RequisitionType: item?.quotType,
                    customerNum: item?.contractNo,
                    exchangeRate: item?.exchangeRate,
                    Description: item?.description,
                    customerId: { label: item?.vendorId, value: item?.vendorId },
                    customerName: { label: item?.vendorName, value: item?.vendorName },
                    paymentTerm: { label: item?.paymentTerms, value: item?.paymentTerms },
                    refNo: item?.boq
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        if (item?.quotationNo) {
            getDocId()
        }
    }, [location?.state]);
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        remarks: "",
        DocumentDate: getCurrentLocalDatetime(),
        SupplierContact: "",
        deliveryDate: getCurrentLocalDatetime(),
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        quotationNo: "",
        RequisitionType: "",
        ContactNo: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        currencyCode: "",
        customerNum: "",
        exchangeRate: "",
        refNo: "",
        SellerName: "",
        customerId: "",
        customerName: "",
        OrderType: [],
        paymentTerm: [],
        SupplierDep: [],
    })
    const handleBackClick = () => {
        window.history.back();
    };
    const handleQuotationData = (data) => {
        setQuotationData(data);
        console.log(data, 'reqData')
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Quotation</h5>
                <div className='d-flex gap-3'>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Quotation Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNO" className="form-label  pt-2 start">Quotation No *</label>
                                <input className="form-Input w-70" disabled value={QotNum} />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNO" className="form-label  pt-2 start">Seller Name</label>
                                <input className="form-Input w-70" disabled
                                    type='text' value={formData.SellerName} name="SellerName"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Currency" className="form-label  pt-2 start">Currency </label>
                                <Select isDisabled value={SelectedCurrency} className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="requisitionNo" className="form-label  pt-2 start">Document Date</label>
                                <input className='form-Input w-70' disabled
                                    id="DocumentDate" type='datetime' name="DocumentDate"
                                    value={moment(formData.DocumentDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryPoint" className="form-label  pt-2 start">Contact Person</label>
                                <input type="text" className="form-Input w-70" disabled
                                    id="contactPerson" name="contactPerson" value={formData.contactPerson}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ExchangeRate" className="form-label  pt-2 start">Exchange Rate</label>
                                <input type="text" className="form-Input w-70" disabled
                                    id="exchangeRate" value={formData.exchangeRate} name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="refNo" className="form-label  pt-2 start">Ref No</label>
                                <input type="number" className="form-Input w-70" disabled id="refNo" name="refNo" value={formData.refNo} />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="location" className="form-label  pt-2 start">Contact No</label>
                                <input type="number" className="form-Input w-70" disabled name="ContactNo" value={formData.ContactNo} />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center" style={{ cursor: 'not-allowed', position: 'relative' }}>
                                <input type="file" ref={fileInputRef} disabled style={{ display: 'none' }} />
                                <div className="file-name">{attachmentFile ? attachmentFile.name : 'Attachment'}</div>
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="RequisitionType" className="form-label  pt-2 start">Remarks</label>
                                <textarea type="text" className="form-Input w-70" disabled id="remarks" name="remarks" value={formData.remarks} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setCustomerTab(!CustomerTab)}>
                    <div className='h6 pt-1'>Customer Details</div>
                </div>
                <div className={`slide-content ${CustomerTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Customer ID</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerId}
                                    cacheOptions isDisabled
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Customer Name</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerName}
                                    cacheOptions
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Payment terms</label>
                                <Select
                                    className="w-70 p-0"
                                    value={Payment}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-2 start">Customer Dept</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label  pt-2 start">Customer Contact</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70" disabled
                                    id="customerNum"
                                    value={formData.customerNum}
                                    name="customerNum"
                                />
                            </div>
                            <div className="col-4 pt-1 d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox" disabled
                                            className="form-check-input"
                                            checked={isVatExcluded}
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Vat Excluded
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox" disabled
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Discount in %
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label  pt-2 start">Property Group</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter1}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label  pt-2 start">Property</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter2}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label  pt-2 start">Department</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter3}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label  pt-2 start">Outlet</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter4}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label  pt-2 start">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Additional Charges
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 mb-3">
                {gridVisibility[0] && (
                    <QuotItemsView onQuotationData={handleQuotationData} selectedCheck={isVatExcluded} itemData={itemData} />
                )}
            </div>
        </div>
    );
};
export default QuotationView; 