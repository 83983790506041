import React, { useEffect, useState, useRef } from 'react';
import { FaAngleLeft } from "react-icons/fa6";
import Select from 'react-select';
import ItemsGrid from './itemGrid';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
const DnView = () => {
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [ItemsData, setRequisitionData] = useState([]);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [activeTab, setActiveTab] = useState(0);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [DnNum, setDnNum] = useState()
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const location = useLocation();
    const item = location.state;

    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    }

    useEffect(() => {
        const item = location.state;
        const getDocId = async () => {
            try {
                
                setIsVatExcluded(item?.vatInclusive)
                const DocumentDate = item?.docDate ? formatDateToDatetimeLocal(item?.docDate) : getCurrentLocalDatetime();
                const invoiceDate = item?.delivery_Date ? formatDateToDatetimeLocal(item?.delivery_Date) : getCurrentLocalDatetime();
                const saleDate = item?.deliveryNoteDate ? formatDateToDatetimeLocal(item?.deliveryNoteDate) : getCurrentLocalDatetime();
                setSelectedCurency({ label: item?.currencyCode, value: item?.currencyCode })
                setDnNum(item?.dnNo)
                setFormData({
                    DocumentDate: DocumentDate,
                    invoiceDate: invoiceDate,
                    quotationNo: item?.quotationNo,
                    TxinvoiceNo: item?.invoiceRef,
                    saleDate: saleDate,
                    invoiceNo: item?.invoiceNo,
                    currencyCode: item?.currencyCode,
                    contactPerson: item?.contactPerson,
                    remarks: item?.remarks,
                    SupplierContact: item?.contractNo,
                    location: { label: item?.warehouse, value: item?.warehouse },
                    selectedSale: { label: item?.sOrderNo, value: item?.sOrderNo },
                    costCenter1: { label: item?.costCenter1, value: item?.costCenter1 },
                    costCenter2: { label: item?.costCenter2, value: item?.costCenter2 },
                    costCenter3: { label: item?.costCenter3, value: item?.costCenter3 },
                    costCenter4: { label: item?.costCenter4, value: item?.costCenter4 },
                    RequisitionType: item?.soType,
                    exchangeRate: item?.exchangeRate,
                    QuotationReq: item?.quotationRef,
                    Description: item?.description,
                    customerId: item?.vendorId,
                    customerName: item?.vendorName,
                    customerNum: item?.mainContactNo,
                    selectedItems: item,
                    paymentTerm: { label: item?.terms, value: item?.terms },
                });
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        if (item?.dnNo) {
            getDocId()
        }
    }, [location?.state]);
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        remarks: "",
        saleDate: getCurrentLocalDatetime(),
        selectedSale: "",
        deliveryDate: getCurrentLocalDatetime(),
        deliveryNote: "",
        location: "",
        invoiceNo: "",
        TxinvoiceNo: "",
        invoiceDate: getCurrentLocalDatetime(),
        ContactNo: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        currencyCode: "",
        customerNum: "",
        exchangeRate: "",
        customerId: "",
        customerName: "",
        paymentTerm: "",
        delivery_Type: ''
    })
    const handleBackClick = () => {
        window.history.back();
    }
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Delivery Note</h5>
                <div className='d-flex gap-3'>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>DN Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group justify-content-between row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNO" className="form-label  pt-2 start">DN No *</label>
                                <input
                                    className="form-Input w-70"
                                    disabled value={DnNum}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DeliveryDate" className="form-label  pt-2 start">Delivery Date</label>
                                <input className='form-Input w-70' disabled
                                    id="deliveryDate" type='datetime' name="deliveryDate"
                                    value={moment(formData.deliveryDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Currency" className="form-label  pt-2 start">Location </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.location} isDisabled
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DocumentDate" className="form-label  pt-2 start">Sales Order No</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.selectedSale} isDisabled
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryNote" className="form-label  pt-2 start">Delivery Note No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="deliveryNote" disabled
                                    value={formData.deliveryNote}
                                    name="deliveryNote"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="TxinvoiceNo" className="form-label  pt-2 start">Tax Invoice No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="TxinvoiceNo" disabled
                                    value={formData.TxinvoiceNo}
                                    name="TxinvoiceNo"
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="requisitionNo" className="form-label  pt-2 start">Sales Order Date</label>
                                <input className='form-Input w-70' disabled
                                    id="saleDate" type='datetime' name="saleDate"
                                    value={moment(formData.saleDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="invoiceNo" className="form-label  pt-2 start">Invoice No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="invoiceNo"disabled
                                    value={formData.invoiceNo}
                                    name="invoiceNo"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label  pt-2 start">Delivery Type</label>
                                <Select isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="RequisitionType" className="form-label  pt-2 start">Customer</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="customerName"
                                    name="customerName" disabled
                                    value={formData.customerName}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="invoiceDate" className="form-label  pt-2 start">Invoice Date</label>
                                <input className='form-Input w-70' disabled
                                    id="invoiceDate" type='datetime' name="invoiceDate"
                                    value={moment(formData.invoiceDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex">
                                <div className="row  p-0 m-0">
                                    <div className="col d-flex">
                                        <label htmlFor="Remarks" className="form-label  pt-2 start">Currency</label>
                                        <Select
                                            isSearchable={false} isDisabled
                                            value={SelectedCurrency}
                                            className="w-70 p-0"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                padding: 9
                                            })}
                                        />
                                    </div>
                                    <div className="col d-flex">
                                        <label htmlFor="QuotationReq" className="form-label  pt-2 start">Exchange Rate</label>
                                        <input
                                            type="text"
                                            className="form-Input w-70"
                                            disabled
                                            id="exchangeRate"
                                            value={formData.exchangeRate}
                                            name="exchangeRate"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label  pt-2 start">Property Group</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    value={formData.costCenter1}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label  pt-2 start">Property</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    value={formData.costCenter2}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label  pt-2 start">Department</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    value={formData.costCenter3}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label  pt-2 start">Outlet</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    value={formData.costCenter4}
                                    isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label  pt-2 start">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    value={formData.CostCentre5}
                                    isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Additional Charges
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 mb-3">
                {gridVisibility[0] && (
                    <ItemsGrid onRequisitionData={handleRequisitionData} selectedCheck={isVatExcluded} itemData={formData} eye={item?.eye}/>
                )}
            </div>
        </div>
    );
};
export default DnView; 