import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../../services/apiServices';
import InvApiService from '../../../INVENTORY/services/apiService';
import Select from 'react-select';
import ApprovalUserGrid from './grid';
import { toast } from 'react-toastify';
import "./approval.css"
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { getUserName } from '../../../Redux/selecters';
import { ClipLoader } from 'react-spinners';
const EditApprovalUser = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [Department, setDepartment] = useState([]);
    const [Active, setActive] = useState(false);
    const [DelegateActive, setDelegateActive] = useState(false);
    const [AutoAprove, setAutoAprove] = useState(false);
    const [pagination, setPagination] = useState();
    const [Users, serUsers] = useState([]);
    const [SupplierTab, setSupplierTab] = useState(false);
    const [POTab, setPOTab] = useState(true);
    const invApiService = new InvApiService()
    const apiService = new ApiService()
    const userName = useSelector(getUserName);
    const [approvalList, setApprovalList] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [loader, setLoader] = useState(false);
    const approvalItem = location?.state
    const [formData, setFormData] = useState({
        DocType: [],
        UserNo: [],
        UserRole: [],
        UserEmail: "",
        UserLevel: "",
        recordId: "",
        Signature: "",
        amountto: '',
        amountFrom: '0',
        delegateto: '',
        date: '',
        Department: [],
    })
    const DocType = [
        { value: 'SQuotation', label: 'Quotation' },
        { value: 'SOrder', label: 'Sale Order' },
        { value: 'Delivery Note', label: 'Delivery Note' },
        { value: 'DN Invoice', label: 'Invoice' },
        { value: 'Sale Return', label: 'Sales Return' },
    ]
    useEffect(() => {
        fetchApprovalData();
    }, []);
    useEffect(() => {
        const item = location?.state;
        const fetchData = async () => {
            const departmentList = await invApiService.get("GET_DEPARTMENT_MASTER");
            const UsersList = await invApiService.get("GET_USERS_LIST");
            const response = await apiService.get('GET_APPROVAL_USER');
            setDepartment(departmentList?.data?.result);
            serUsers(UsersList?.data?.result);
            const approvals = response.data.result?.filter(dep => dep.docType === item?.docType)
            setRowData(approvals)
            setApprovalList(response.data.result)
            const selectedUser = UsersList?.data?.result?.find(user => user.emailId === item?.userNo)
            const delegateTo = UsersList?.data?.result?.find(user => user.emailId === item?.delegateTo)
            const selectedDepartment = departmentList?.data?.result?.find(dep => dep.description === item?.department)
            const selectedDocType = DocType?.find(doc => doc.value === item?.docType)
            setFormData({
                recordId: item?.rcid || "",
                UserLevel: item?.userLevel || "",
                UserNo: { label: selectedUser?.user_Name, value: selectedUser },
                UserRole: { label: selectedUser?.role, value: selectedUser?.role },
                UserEmail: selectedUser?.emailId,
                amountFrom: item?.fromPOAmount,
                amountto: item?.toPOAmount,
                date: item?.delegateUntil,
                delegateto: { label: delegateTo?.user_Name, value: delegateTo },
                Department: { label: selectedDepartment?.description, value: selectedDepartment },
                Signature: item?.sigFilePath,
                DocType: { label: selectedDocType?.label, value: selectedDocType?.value }
            });
            setActive(item?.active === 1 ? true : false)
            setAutoAprove(item?.isAutoApprove === 1 ? true : false)
            setDelegateActive(item?.delegateTo === "" ? false : true)
        }
        if (item) {
            fetchData()
        }
    }, [location?.state]);
    const handleActiveSelection = (e) => {
        setActive(e);
    }
    const handleDeleActiveSelection = (e) => {
        setDelegateActive(e);
    }
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const fetchApprovalData = async (newPageNo = pagination?.currentPage) => {
        try {
            const response = await apiService.get('GET_APPROVAL_USER');
            setPagination(response.data.paginationData)
            const approvals = response.data.result?.filter(dep => dep.active === 1)
            setRowData(approvals);
            setApprovalList(approvals)
        } catch (error) {
            console.error("Error fetching packing data:", error);
        }
    }
    const updateFormData = async () => {
        try {
            setLoader(true)
            if (!formData?.UserNo?.label) {
                toast.warning("Select User to create Approval");
                setLoader(false)
                return;
            }
            if (!formData?.DocType?.value) {
                toast.warning("Select Document Type");
                setLoader(false)
                return;
            }
            if (!formData?.UserLevel) {
                toast.warning("Enter User level for Approval");
                setLoader(false)
                return;
            }
            const currentDatetimeUTC = new Date().toISOString();
            let body = {
                rcid: approvalItem?.rcid,
                docType: formData?.DocType?.value,
                userNo: formData?.UserNo?.label,
                userLevel: formData?.UserLevel,
                department: formData?.Department?.label,
                active: Active === true ? 1 : 0,
                sigFilePath: formData?.Signature,
                isAutoApprove: AutoAprove === true ? 1 : 0,
                createdBy: userName,
                stockTypeName: '',
                fromPOAmount: formData?.amountFrom || 0,
                toPOAmount: formData?.amountto || 0,
                delegateTo: formData?.delegateto?.label,
                delegateUntil: formData.date || currentDatetimeUTC.toString(),
                isDelegateUserActive: DelegateActive
            }
            const res = await apiService.put(`UPDATE_APPROVAL_USER`, body);
            if (res?.data?.statusCode === 201) {
                setLoader(false)
                toast.success(res.data.message)
                navigate('/Sales/ApprovalUser/List');
            } else {
                setLoader(false)
                toast.warning(res.data.message)
            }
        } catch (error) {
            setLoader(false)
            console.error('Error updating form data:', error);
        }
    }
    const handleDocTypeChange = (selectedOption) => {
        setFormData({ ...formData, DocType: selectedOption });
        const approvals = approvalList?.filter(dep => dep.docType === selectedOption?.value)
        setRowData(approvals)
    }
    const handleDepartmentChange = (selectedOption) => {
        setFormData({ ...formData, Department: selectedOption });
    }
    const handleUsersDrop = (selectedOption) => {
        setFormData({
            ...formData,
            UserNo: selectedOption,
            UserEmail: selectedOption?.value.emailId,
            UserRole: { label: selectedOption?.value?.role, value: selectedOption?.value?.role },
            Department: { label: selectedOption?.value?.department, value: selectedOption?.value?.department },
        });
    }
    const handleBackClick = () => {
        window.history.back();
    };
    const handleReset = () => {
        setFormData({
            DocType: [],
            UserNo: [],
            UserLevel: "",
            recordId: "",
            Signature: "",
            Department: [],
        });
    };
    return (
        <div className='px-4 pb-5'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Approval User</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' onClick={updateFormData}><FaSave className='center mt-1 mx-2' /><div>Update</div></button>
                    }
                    <button className='mainbtnRed' onClick={handleReset}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtn'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Users Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Name</label>
                                <Select
                                    value={formData.UserNo}
                                    onChange={handleUsersDrop}
                                    options={Users?.map((sup) => ({ value: sup, label: sup?.user_Name }))}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Role</label>
                                <Select
                                    value={formData.UserRole} isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Email</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="UserEmail" disabled
                                    value={formData?.UserEmail}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">Signature</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="Signature"
                                    value={formData?.Signature}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between gap-3">
                                <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="Active" style={{ width: "20px", height: "20px" }}
                                        name="Active"
                                        checked={Active}
                                        onChange={(e) => handleActiveSelection(e.target.checked)}
                                    />
                                    <label className='px-2'>Active</label>
                                </div>
                                {/* <div className='gap-3 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="AutoAprove" style={{ width: "20px", height: "20px" }}
                                        name="AutoAprove"
                                        checked={AutoAprove}
                                        onChange={(e) => handleAutoApprove(e.target.checked)}
                                    />
                                    <label className='px-2'>Auto Aprove</label>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Approval Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2  ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row p-3">
                            <div className="col-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Doc Type</label>
                                <Select
                                    value={formData?.DocType}
                                    options={DocType?.map((doc) => ({ value: doc?.value, label: doc?.label }))}
                                    onChange={handleDocTypeChange}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom02" className="form-label  pt-2 start">User Level</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="validationCustom02"
                                    name="UserLevel"
                                    value={formData?.UserLevel}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-4 justify-content-around d-flex">
                                <label htmlFor="validationCustom01" className="form-label  pt-2 start">Department</label>
                                <Select
                                    value={formData.Department}
                                    onChange={handleDepartmentChange}
                                    options={Department?.map((sup) => ({ value: sup, label: sup?.description }))}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row px-3 pb-2">
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="amountFrom" className="form-label  pt-2 start">Amount From</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="amountFrom"
                                    name="amountFrom"
                                    value={formData?.amountFrom}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="amountto" className="form-label  pt-2 start">Amount To</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    id="amountto"
                                    name="amountto"
                                    value={formData?.amountto}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <div className="form-group row p-3 m-0 bgbox1" >
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="delegateto" className="form-label  pt-2 start">Delegate To</label>
                                <Select
                                    isDisabled={DelegateActive === true ? false : true}
                                    value={formData.delegateto}
                                    onChange={handleUsersDrop}
                                    options={Users?.map((sup) => ({ value: sup, label: sup?.user_Name }))}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between ">
                                <label htmlFor="amountto" className="form-label  pt-2 start">Until This Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="date"
                                    name="date"
                                    value={formData?.date}
                                    disabled={DelegateActive === true ? false : true}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between ">
                                <div className='gap-2 center '>
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="Active" style={{ width: "20px", height: "20px" }}
                                        name="Active"
                                        checked={DelegateActive}
                                        onChange={(e) => handleDeleActiveSelection(e.target.checked)}
                                    />
                                    <label className='px-2'>Active</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSupplierTab(!SupplierTab)}>
                    <div className='h6 pt-1'>Approval User List</div>
                </div>
                <div className={`slide-content ${SupplierTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 ${SupplierTab ? 'slide-in' : 'slide-out'}`}>
                        <ApprovalUserGrid rowData={rowData} fetchApprovalData={fetchApprovalData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditApprovalUser;
