import React from 'react';
import { useLocation } from 'react-router-dom';
import PMSideBar from '../Components/Sidebar/pMSideBar';
const PMSideLayout = ({ children }) => {
  const location = useLocation();
  const isSidebarEnabled = location.pathname.startsWith('/Property');
  
  return (
    <div className="sidebar-layout">
      <div className={`sidebar ${isSidebarEnabled ? 'fixed-lefty' : ''}`}>
         <PMSideBar currentPath={location.pathname} />
      </div>
      <main className="main-content">{children}</main>
    </div>
  )
}

export default PMSideLayout;