import React, { useEffect, useState } from "react";
import Dash from "../../Assets/Images/dash.png";
import "./dashboard.css";

const PropertyDashBoard = () => {

    return (
        <div className="">
            <div><img src={Dash} className="chartimg" /></div>
        </div>
    );
};

export default PropertyDashBoard;
