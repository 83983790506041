import React, { useState } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, NavLink, useLocation } from "react-router-dom";
import YourLogo from "../../Assets/Images/Pro_Logo.png";
import "../../Assets/Style/style.css"
import "./sidebar.css"
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineStock, AiOutlineBulb } from "react-icons/ai";
import { TbFileInvoice, TbRepeat, TbTruckDelivery, TbUpload } from "react-icons/tb";
import { useSelector } from "react-redux";
import { getCompany } from "../../../Redux/selecters";

const PMSideBar = () => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const companies = useSelector(getCompany);

    const handleToggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            <ProSidebar collapsed={collapsed}>
                {collapsed ? (
                    <a href="/Inventory" className="navbar-brand start">
                        <img
                            src={companies?.selectedCompany?.logo || YourLogo}
                            alt="Logo"
                            style={{ height: "40px", paddingTop: "8px", paddingBottom: "8px" }}
                        />
                    </a>
                ) : (
                    <a href="/Inventory" className="navbar-brand start">
                        <img
                            src={companies?.selectedCompany?.logo || YourLogo}
                            alt="Logo"
                            style={{ height: "35px" }}
                        />
                        <span className="logoName" style={{ marginLeft: "8px" }}>
                            {companies?.selectedCompany?.logo
                                ?.split('/')
                                .pop()
                                ?.split('.')
                                .slice(0, -1)
                                .join('.')
                                ?.charAt(0)
                                .toUpperCase() +
                                companies?.selectedCompany?.logo
                                    ?.split('/')
                                    .pop()
                                    ?.split('.')
                                    .slice(0, -1)
                                    .join('.')
                                    ?.slice(1)
                                    .toLowerCase()}
                        </span>
                    </a>
                )}
                <Menu className="mt-2">
                    <MenuItem
                        icon={<AiOutlineStock size={23} />}
                        active={location.pathname.startsWith('/Property/Management/Dashboard')}
                    >
                        <NavLink to="/Property/Management/Dashboard">
                            <div>DashBoard</div>
                        </NavLink>
                    </MenuItem>
                    <MenuItem
                        icon={<AiOutlineBulb size={23} />}
                        active={location.pathname.startsWith('/Property/Management/LeaseAgreement')}
                    >
                        <NavLink to="/Property/Management/LeaseAgreement">
                            <div className={`submenu ${location.pathname === "/Property/Management/LeaseAgreement" ? 'subactive' : ''}`}>Lease Agreement</div>
                        </NavLink>
                    </MenuItem>
                </Menu>
                <div
                    onClick={handleToggle}
                    className="sidebar-toggle-btn"
                    style={{ left: collapsed ? '100px' : '300px' }}
                >
                    <RxHamburgerMenu size={24} />
                </div>
            </ProSidebar>
        </div>
    );
};

export default PMSideBar;
