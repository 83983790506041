import React, { useState, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { FaRegEye } from "react-icons/fa6";
import { AiOutlinePrinter } from "react-icons/ai";
import { clearRows } from '../../../Redux/actions/rowActions';
import { useDispatch } from 'react-redux';
import "../pagination/pagination.css"
import { IoAddSharp } from "react-icons/io5";
import Pagination from '../pagination/pagination';
import { GrEdit } from "react-icons/gr";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Select from 'react-select';
import { RiRefreshLine } from "react-icons/ri";
import { FaFileCircleExclamation } from "react-icons/fa6";
import LeaseApiService from '../../services/apiService';
const LeaseListing = () => {
    const [rowData, setRowData] = useState([]);
    const [proprtyList, setProperty] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedtem, setSelectedItem] = useState(null);
    const apiService = new LeaseApiService();
    const [FilterTab, setFilterTab] = useState(true);
    const [permissions, setPermissions] = useState([]);
    let navigate = useNavigate();
    let dispatch = useDispatch()
    const StatusFilter = [
        { value: 'All', label: 'All' },
        { value: '0', label: 'Active' },
        { value: '1', label: 'Renewed' },
        { value: '2', label: 'Terminated' },
    ];

    const [formData, setFormData] = useState({
        email: "",
        Proprty: [{ label: 'Select Property', value: '' }],
        Status: [{ label: 'All', value: '' }]
    });

    useEffect(() => {
        getItems();
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "Purchase Requisition");
        setPermissions(permissions[0]);
    }, []);

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                getItems()
                const params = new URLSearchParams();
                params.append('dropdownTypes', 'LASerPropertyName');
                const res = await apiService.getApiParamater('GET_PROPERT_DROP', `?${params.toString()}`);
                setProperty(res?.data?.dropdowns?.LASerPropertyName)
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };
        fetchDropdownData();
    }, []);

    useEffect(() => {
        if (pageSize) {
            getItems();
        } else if (formData) {
            getItems();
        }
    }, [pageSize, formData]);
    const handleEdit = async () => {
        let eye = false
        try {

            if (selectedtem?.requisitionNo) {
                const updatedData = { ...selectedtem, eye: eye };
                if (updatedData?.status === 1) {
                    const updatedData = { ...selectedtem, eye: !selectedtem.eye?.active };
                    dispatch(clearRows());
                    navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
                } else {
                    dispatch(clearRows());
                    navigate("/Inventory/Requisition/PurchaseRequisition", { state: selectedtem });
                }
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    const handlePrint = () => {
        if (selectedRow) {
            navigate(`/Inventory/Requisition/${selectedRow?.requisitionNo}`, { state: selectedRow })
        }
    }
    const handleEye = (clickedRowData) => {
        if (clickedRowData?.requisitionNo) {
            const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
            navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
        } else if (selectedRow?.requisitionNo) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate("/Inventory/Requisition/PurchaseRequisition", { state: updatedData });
        }
    }
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            params.append('PropertyName', formData?.Proprty?.label || '');
            params.append('Status', formData?.Status?.label || '');
            debugger
            const res = await apiService.getApiParamater('GET_LEASE_AGRE', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            // Handle error (e.g., show error message to user)
            setLoading(false); // Set loading to false even in case of error
        }
    }
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("DD-MMM-YYYY");
    };
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'Lease No', field: 'leaseNo', filter: true, floatingfilter: true, suppressMovable: true, width: 140 },
        { headerName: 'Property', field: 'property_Name', filter: true, floatingfilter: true, suppressMovable: true, width: 250 },
        { headerName: 'Tenant', field: 'tenantName', filter: true, floatingfilter: true, suppressMovable: true, width: 250 },
        { headerName: 'Unit Num', field: 'unit_No', filter: true, floatingfilter: true, suppressMovable: true, width: 100 },
        {
            headerName: "Period From", field: "leaseDate", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator, suppressMovable: true, width: 136
        },
        {
            headerName: "Period To", field: "leaseExpiry", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator, suppressMovable: true, width: 136
        },
        { headerName: 'Total', field: 'total', filter: true, floatingfilter: true, suppressMovable: true, width: 140 },
        { headerName: 'Area', field: 'totalArea', filter: true, floatingfilter: true, suppressMovable: true, width: 100 },
        { headerName: 'Tax', field: 'tax', filter: true, floatingfilter: true, suppressMovable: true, width: 140 },
        { headerName: 'Remarks', field: 'remarks', filter: true, floatingfilter: true, suppressMovable: true, width: 140 },
        { headerName: 'OutLet Name', field: 'outletName', filter: true, floatingfilter: true, suppressMovable: true, width: 140 },
        {
            headerName: 'Status', field: 'status', filter: true, floatingfilter: true, suppressMovable: true, width: 140
        },
        {
            headerName: "Created Date", field: "createdOn", filter: true, floatingfilter: true,
            valueFormatter: dateFormatter,
            comparator: dateComparator, suppressMovable: true, width: 136
        },
    ];
    const onCellClicked = async (event) => {
        if (event.colDef.field === 'requisitionNo') {
            const clickedRowData = event.data;
            handleEye(clickedRowData)
        }
    };
    const onSelectionChanged = async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
        const params = new URLSearchParams();
        params.append('DocNo', selectedRows[0]?.requisitionNo);
        params.append('DocumentType', "Purchase Requisition");

        try {
            const res = await apiService.getApiParamater('GET_DOC_STATUS_EDIT', `?${params.toString()}`);
            const statuses = res?.data?.result?.map(item => item.status);
            const allAreZero = statuses.every(status => status === 0);
            const anyIsFive = statuses.some(status => status === 2);
            if (allAreZero || anyIsFive) {
                setSelectedItem(selectedRows.length === 1 ? selectedRows[0] : null);
            } else {
                console.warn("No valid status to set the item.");
                setSelectedItem(null);
            }
        } catch (error) {
            setSelectedItem(null);
            console.error("Error fetching document status:", error);
        }
    };
    const clearAllRows = () => {
        dispatch(clearRows());
    };
    const handlenew = () => {
        clearAllRows();
        navigate('/Inventory/Requisition/PurchaseRequisition')
    }
    const handleClearFilters = () => {
        setFormData({
            Proprty: [{ label: 'Select Property', value: '' }],
            Status: [{ label: 'All', value: '' }],
        });
    };
    const handlePropertyChange = (selectedOption) => {
        setFormData({ ...formData, Proprty: selectedOption });
    }
    const handleStatusFilter = (selectedOption) => {
        if (selectedOption.label == "All") {
            handleClearFilters()
        } else {
            setFormData({ ...formData, Status: selectedOption });
        }
    }
    return (
        <div className='p-4 '>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setFilterTab(!FilterTab)}>
                    <div className='h6 pt-1'>Search Parameters</div>
                </div>
                <div className={`slide-content ${FilterTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${FilterTab ? 'slide-in' : 'slide-out'}`}>
                        <div className='row'>
                            <div className='col-4'>
                                <Select
                                    className="p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    value={formData.Proprty}
                                    onChange={(selectedOption) => handlePropertyChange(selectedOption)}
                                    options={proprtyList?.map((rec) => ({ value: rec?.value, label: rec?.text }))}
                                />
                            </div>
                            <div className='col-4'>
                                <Select
                                    className="p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    value={formData.Status}
                                    options={StatusFilter}
                                    onChange={handleStatusFilter}
                                />
                            </div>
                            <div className='col-4 d-flex gap-1'>
                                <div
                                    // onClick={() => getItems()}
                                    className='mainbtnGry flex-end gap-2 w-50'>
                                    <FaFileCircleExclamation color='#fff' className='center mt-1 mx-2' />
                                    <div className='center'>Search</div>
                                </div>
                                <div
                                    onClick={() => handleClearFilters()}
                                    className='mainbtnRed flex-end gap-2 w-50'>
                                    <RiRefreshLine color='#fff' className='center mt-1 mx-2' />
                                    <div className='center'>Reset</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='border-box2 p-4'>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <div className='d-flex justify-contant-between '>
                            <h5 className='col mainInvHead'>Lease Agreement List</h5>
                            <div className='d-flex justify-content-end gap-1'>
                                <div
                                    onClick={handleEye}
                                    className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <FaRegEye color='#fff' className='center mt-1' />
                                    <div className='center'>View</div>
                                </div>
                                {/* {permissions?.canUpdate === true ? */}
                                <div
                                    onClick={selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? handleEdit : null}
                                    className={`mainbtnSecondary flex-end gap-2 ${!selectedtem || selectedtem?.status === 0 || selectedtem?.status === 2 ? '' : 'disabled'
                                        }`}
                                    style={{
                                        cursor: selectedtem && selectedtem?.status === 0 || selectedtem?.status === 2 ? 'pointer' : 'not-allowed',
                                    }}
                                >
                                    <GrEdit color='#fff' className='center mt-1 ' />
                                    <div className='center'>Edit</div>
                                </div>
                                {/* } */}
                                <div
                                    // onClick={handleRevision}
                                    className={`reversbtn flex-end gap-2 ${!selectedRow ? '' : 'disabled'}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <MdOutlineDeleteOutline color='#fff' className='center mt-1' />
                                    <div className='center'>Delete</div>
                                </div>
                                <div
                                    onClick={handlePrint}
                                    className={`mainbtnRed flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <AiOutlinePrinter color='#fff' className='center mt-1 ' />
                                    <div className='center'>Print</div>
                                </div>
                                {/* {permissions?.canAdd === true ? */}
                                <div className='col center'>
                                    <button className='mainbtn ' type="submit" onClick={() => handlenew()}>
                                        <IoAddSharp color='#fff' className='center mt-1' /> <div>Add</div>
                                    </button>
                                </div>
                                {/* : '' */}
                                {/* } */}
                            </div>
                        </div>
                        <div className='gridshad  mt-3'>
                            <div className="ag-theme-quartz" style={{ height: 650 }}>
                                <AgGridReact
                                    getRowStyle={(params) => {
                                        if (params.node.rowIndex % 2 === 0) {
                                            return { background: '#F0EDF5' };
                                        } else {
                                            return { background: '#fff' };
                                        }
                                    }}
                                    rowData={rowData}
                                    enableFilter={true}
                                    floatingFilter={true}
                                    columnDefs={columnDefs}
                                    onCellClicked={onCellClicked}
                                    onSelectionChanged={onSelectionChanged}
                                />
                            </div>
                            <Pagination pageSize={pageSize} setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                        </div>
                    </>
                )}
            </div>
        </div>

    );
};
export default LeaseListing;
