import React, { useEffect, useState, useRef } from 'react';
import { RiRefreshLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import ItemsGrid from './itemGrid';
import InvApiService from "../../../INVENTORY/services/apiService"
import ApiService from "../../services/apiServices"
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCompany, getName, getUserId, getUserName } from '../../../Redux/selecters';
const CreateDRN = () => {
    const [loader, setLoader] = useState(false);
    const fileInputRef = useRef(null);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [POTab, setPOTab] = useState(true);
    const [CostTab, setCostTab] = useState(false);
    const [ItemsData, setRequisitionData] = useState([]);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [activeTab, setActiveTab] = useState(0);
    const InvapiService = new InvApiService();
    const apiService = new ApiService();
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [DnNum, setDnNum] = useState()
    const [WareHouse, setWareHouse] = useState([]);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const [salesList, setSaleList] = useState([]);
    let navigate = useNavigate();
    const userName = useSelector(getUserName);
    const userId = useSelector(getUserId);
    const Company = useSelector(getCompany);

    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    useEffect(() => {
        getDocId()
    }, [])
    const getDocId = async () => {
        try {
            let param = `?isActive=true`
            let docParam = `?TypeOfDoc=DN&DocId=Delivery Note`
            let DocId = await InvapiService.getApiParamater('GET_DOC_ID', docParam)
            const WareHouse = await InvapiService.get("GET_WAREHOUSE_MASTER");
            setWareHouse(WareHouse?.data?.result);
            let res = await InvapiService.getApiParamater('GET_CURRENCY_MASTER', param)
            let salesList = await apiService.get('GET_DN_SO')
            setSaleList(salesList.data.result)
            const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
            if (filteredDenominations) {
                setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
            }
            setFormData({ exchangeRate: filteredDenominations?.conversionRate })
            setDnNum(DocId?.data.result[0].docId);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    }
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        remarks: "",
        saleDate: getCurrentLocalDatetime(),
        selectedSale: "",
        deliveryDate: getCurrentLocalDatetime(),
        deliveryNote: "",
        location: "",
        invoiceNo: "",
        TxinvoiceNo: "",
        invoiceDate: getCurrentLocalDatetime(),
        ContactNo: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        currencyCode: "",
        customerNum: "",
        exchangeRate: "",
        customerId: "",
        customerName: "",
        paymentTerm: "",
        delivery_Type: ''
    })
    const handleBackClick = () => {
        window.history.back();
    }
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'ContactNo' || name === 'customerNum') && value.length > 16) {
            return;
        }
        if (name === 'deliveryDate' || name === 'DocumentDate') {
            const dateParts = e.target.value.split("-");
            if (dateParts[0].length > 4) {
                return;
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleLocationChange = (selectedOption) => {
        setFormData({ ...formData, location: selectedOption });
    }
    const handleSaleChange = async (selectedOption) => {
        let param = `?SOrderNo=${selectedOption?.label}`
        let res = await apiService.getApiParamater('GET_SALE', param)
        let selectedSaleOrder = res?.data?.result[0]
        const saleorderDate = selectedSaleOrder?.docDate ? formatDateToDatetimeLocal(selectedSaleOrder?.docDate) : getCurrentLocalDatetime();

        setFormData({
            ...formData,
            selectedSale: selectedOption,
            saleDate: saleorderDate,
            customerName: selectedSaleOrder?.vendorName,
            customerId: selectedSaleOrder?.vendorId,
            paymentTerm: selectedSaleOrder?.terms,
            costCenter1: { label: selectedSaleOrder?.costCenter1, value: selectedSaleOrder?.costCenter1 },
            costCenter2: { label: selectedSaleOrder?.costCenter2, value: selectedSaleOrder?.costCenter2 },
            costCenter3: { label: selectedSaleOrder?.costCenter3, value: selectedSaleOrder?.costCenter3 },
            costCenter4: { label: selectedSaleOrder?.costCenter4, value: selectedSaleOrder?.costCenter4 },
        });
        setIsVatExcluded(selectedSaleOrder?.costCenter5 === "Included" ? false : true)
    }
    const CreateDRN = async () => {
        setLoader(true)
        if (ItemsData.length === 0) {
            toast.warning("Please add items");
            setLoader(false)
            return;
        }
        const missingQtyItem = ItemsData.find(item => !item.qty);
        if (missingQtyItem) {
            toast.warning(`Please enter quantity for ${missingQtyItem.itemDescription}`);
            setLoader(false)
            return;
        }
        const totalAmount = ItemsData.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
        const vatAmount = ItemsData.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
        const grossAmount = parseFloat(totalAmount) - parseFloat(vatAmount || 0);
        try {
            const currentDatetimeUTC = new Date().toISOString();
            const DRNDetailsData = ItemsData.map((item, index) => {
                const grossAmount = parseFloat(item.amount) - parseFloat(item.vatAmount || 0);
                return {
                    dnNo: DnNum,
                    vatPerc: item?.vat,
                    vatDesc: item?.vatDec,
                    vatAmount: item.vatAmount,
                    vatUnitPrice: item.vatAmount / parseInt(item.qty),
                    vatBaseAmount: item.vatAmount,
                    srno: index + 1,
                    itemCode: item.ItemNo || item.recordId,
                    itemDescription: item.itemDescription,
                    uom: item.uom,
                    qty: parseInt(item.qty),
                    specification: item.specification,
                    unitPrice: parseFloat(item.unitPrice),
                    amount: parseFloat(item.amount),
                    baseAmount: parseFloat(item.amount),
                    budgetCode: '',
                    invoicedQty: 0,
                    extraQty: 0,
                    convToInvUOM: item.convRate,
                    importedQty: 0,
                    prodDate: currentDatetimeUTC,
                    expDate: item.expDate,
                    batchNo: '',
                    addCharges: 0,
                    inventoryAccountCode: item.inventoryAccountCode,
                    inventoryAccountName: item.inventoryAccountName,
                    vatCode: item?.vat,
                    vatInclusive: isVatExcluded,
                    grossAmount: parseFloat(grossAmount),
                    grossBaseAmount: parseFloat(grossAmount),
                }
            });
            const userData = {
                transport_Mode: '',
                transporter: '',
                batchNo: '',
                vatCode: 0,
                isDeleted: true,

                dnNo: DnNum,
                invoiceNo: formData.invoiceNo,
                invoiceDate: formData.invoiceDate,
                invoiceRef: formData.TxinvoiceNo,
                sOrderNo: formData?.selectedSale.label,
                delivery_Type: formData.delivery_Type,
                warehouse: formData.location.label,
                approvalUser: userName,
                approvalDate: currentDatetimeUTC,
                vatInclusive: true,
                amount: parseFloat(totalAmount),
                baseAmount: parseFloat(totalAmount),
                grossAmount: parseFloat(grossAmount),
                grossBaseAmount: parseFloat(grossAmount),
                vatAmount: parseFloat(vatAmount),
                isEditable: true,
                currencyCode: SelectedCurrency?.label,
                exchangeRate: SelectedCurrency?.value.conversionRate,
                department: formData?.Department,
                status: 0,
                modifiedBy: userName,
                remarks: formData?.remarks,
                costCenter1: formData?.costCenter1?.label,
                costCenter2: formData?.costCenter2?.label,
                costCenter3: formData?.costCenter3?.label,
                costCenter4: formData?.costCenter4?.label,
                isActive: true,
                deliveryNoteDetails: DRNDetailsData,
                vendorId: formData.customerId,
                vendorName: formData.customerName,
                delivery_Date: formData.deliveryDate || currentDatetimeUTC,
                deliveryNoteDate: formData.deliveryDate || currentDatetimeUTC,
                terms: formData.paymentTerm,
                approvalDate: currentDatetimeUTC,
                modifiedOn: currentDatetimeUTC,
            };

            const response = await apiService.post("CREATE_DN", userData);
            if (response?.data?.statusCode === 201) {
                const userData2 = {
                    docType: "Delivery Note",
                    docId: DnNum ? DnNum.toString() : DnNum,
                    department: "IT",
                    company: Company?.selectedCompany?.companyNo,
                    userCode: userId,
                    userName: userName,
                };

                const response2 = await apiService.post("CREATE_DOC_AUTHORIZATION", userData2);
                const paramsGet = new URLSearchParams();
                paramsGet.append('docType', "Delivery Note");
                paramsGet.append('docId', DnNum);
                paramsGet.append('srno', 1);
                const response3 = await apiService.getApiParamater('GET_DOC_APPROVAL_USER', `?${paramsGet.toString()}`);
                if (response3?.data?.result.length > 0) {
                    const toEmails = response3?.data?.result?.map(user => user.userEmail);
                    const emailbody = {
                        toEmails: toEmails,
                        subject: "Delivery Note",
                        body: "",
                        docNo: DnNum,
                        srNo: 1,
                        docType: "Delivery Note"
                    };
                    const emailres = await apiService.post("DOC_APPROVAL_EMAIL_POST", emailbody);
                }
                const docBody = {
                    docId: "Delivery Note",
                    typeOfDoc: "DN",
                };
                const docRes = await InvapiService.put("UPDATE_DOC_ID", docBody);
                setLoader(false)
                toast.success(`${DnNum} Created successfully`);
                navigate("/Sales/DRN")
            }
        } catch (error) {
            setLoader(false)
            console.error("Error Creating PO:", error);
        }
    }

    const handleReset = () => {
        setFormData({
            PayOrderNo: "",
            remarks: "",
            saleDate: getCurrentLocalDatetime(),
            selectedSale: "",
            deliveryDate: getCurrentLocalDatetime(),
            deliveryNote: "",
            location: "",
            invoiceNo: "",
            TxinvoiceNo: "",
            invoiceDate: getCurrentLocalDatetime(),
            ContactNo: "",
            costCenter1: "",
            costCenter2: "",
            costCenter3: "",
            costCenter4: "",
            CostCentre5: "",
            currencyCode: "",
            customerNum: "",
            exchangeRate: "",
            customerId: "",
            customerName: "",
            paymentTerm: "",
            delivery_Type: ''
        });
        setWareHouse([])
        setIsVatExcluded(false)
    }
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Delivery Note</h5>
                <div className='d-flex gap-3'>
                    {loader === true ?
                        <button className='mainbtn ' type="submit"><ClipLoader color="#ffffff" size={25} /></button> :
                        <button className='mainbtn ' onClick={CreateDRN} type="submit"><FaSave className='center mt-1 mx-2' /><div>Save</div></button>
                    }
                    <button className='mainbtnRed' onClick={handleReset}><RiRefreshLine className='center mt-1 mx-2' /><div>Reset</div></button>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>DN Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group justify-content-between row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNO" className="form-label  pt-2 start">DN No *</label>
                                <input
                                    className="form-Input w-70"
                                    disabled value={DnNum}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DeliveryDate" className="form-label  pt-2 start">Delivery Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-Input w-70"
                                    id="DeliveryDate" min={currentDatetimeUTC}
                                    value={formData.deliveryDate}
                                    onChange={handleInputChange}
                                    name="deliveryDate"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Currency" className="form-label  pt-2 start">Location </label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.location}
                                    onChange={handleLocationChange}
                                    options={WareHouse?.map((sup) => ({ value: sup, label: sup?.description }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DocumentDate" className="form-label  pt-2 start">Sales Order No</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.selectedSale}
                                    onChange={handleSaleChange}
                                    options={salesList?.map((sup) => ({ value: sup?.sOrderNo, label: sup?.sOrderNo }))}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryNote" className="form-label  pt-2 start">Delivery Note No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="deliveryNote"
                                    value={formData.deliveryNote}
                                    onChange={handleInputChange}
                                    name="deliveryNote"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="TxinvoiceNo" className="form-label  pt-2 start">Tax Invoice No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="TxinvoiceNo"
                                    value={formData.TxinvoiceNo}
                                    onChange={handleInputChange}
                                    name="TxinvoiceNo"
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="requisitionNo" className="form-label  pt-2 start">Sales Order Date</label>
                                <input type='dateTime-local'
                                    value={formData.saleDate}
                                    className="form-Input w-70" name='requisitionNo'
                                    id="requisitionNo" disabled
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="invoiceNo" className="form-label  pt-2 start">Invoice No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="invoiceNo"
                                    value={formData.invoiceNo}
                                    onChange={handleInputChange}
                                    name="invoiceNo"
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label  pt-2 start">Delivery Type</label>
                                <Select
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="RequisitionType" className="form-label  pt-2 start">Customer</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="customerName"
                                    name="customerName" disabled
                                    value={formData.customerName}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="invoiceDate" className="form-label  pt-2 start">Invoice Date</label>
                                <input
                                    type="dateTime-local"
                                    className="form-Input w-70" min={currentDatetimeUTC}
                                    id="invoiceDate"
                                    name="invoiceDate"
                                    value={formData.invoiceDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col d-flex">
                                <div className="row  p-0 m-0">
                                    <div className="col d-flex">
                                        <label htmlFor="Remarks" className="form-label  pt-2 start">Currency</label>
                                        <Select
                                            isSearchable={false} isDisabled
                                            value={SelectedCurrency}
                                            className="w-70 p-0"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                padding: 9
                                            })}
                                        />
                                    </div>
                                    <div className="col d-flex">
                                        <label htmlFor="QuotationReq" className="form-label  pt-2 start">Exchange Rate</label>
                                        <input
                                            type="text"
                                            className="form-Input w-70"
                                            disabled
                                            id="exchangeRate"
                                            value={formData.exchangeRate}
                                            name="exchangeRate"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label  pt-2 start">Property Group</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    value={formData.costCenter1}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label  pt-2 start">Property</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    value={formData.costCenter2}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label  pt-2 start">Department</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    isDisabled
                                    value={formData.costCenter3}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label  pt-2 start">Outlet</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    value={formData.costCenter4}
                                    isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label  pt-2 start">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    value={formData.CostCentre5}
                                    isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Additional Charges
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 mb-3">
                {gridVisibility[0] && (
                    <ItemsGrid onRequisitionData={handleRequisitionData} selectedCheck={isVatExcluded} itemData={formData} />
                )}
            </div>
        </div>
    );
};
export default CreateDRN; 