import React, { useState, useEffect, Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/apiServices';
import { useNavigate } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { IoAddSharp } from "react-icons/io5";
import { GrEdit } from "react-icons/gr";
import { FaRegEye } from "react-icons/fa6";
import Pagination from '../../../INVENTORY/Pages/pagination/pagination';

const ApprovalListing = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState();
    let navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const [pagination, setPagination] = useState();
    const [selectedRow, setSelectedRow] = useState(null);
    useEffect(() => {
        if (pageSize) {
            getItems();
        }
        const storedPermissions = JSON.parse(localStorage.getItem('RoleAccess')) || [];
        const permissions = storedPermissions?.filter(acc => acc.formName === "Approval User");
        setPermissions(permissions[0]);
    }, [pageSize]);
    const handleEdit = () => {
        if (selectedRow) {
            debugger
            navigate(`/Sales/ApprovalUser/${selectedRow?.rcid}`, { state: selectedRow });
        }
    };
    const handleEye = (clickedRowData) => {
        // if (permissions?.canView === true) {
        if (clickedRowData?.userNo) {
            const updatedData = { ...clickedRowData, eye: !clickedRowData.eye?.active };
            navigate(`/Sales/ApprovalUser/${updatedData?.docType}/${updatedData?.userNo}`, { state: updatedData });
        } else if (selectedRow) {
            const updatedData = { ...selectedRow, eye: !selectedRow.eye?.active };
            navigate(`/Sales/ApprovalUser/${updatedData?.docType}/${updatedData?.userNo}`, { state: updatedData });
        }
        // }
    }
    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRow(selectedRows.length === 1 ? selectedRows[0] : null);
    };
    const getItems = async (newPageNo = pagination?.currentPage) => {
        try {
            const apiService = new ApiService();
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', 50);
            const res = await apiService.getApiParamater('GET_APPROVAL_USER', `?${params.toString()}`);
            setRowData(res?.data?.result || []);
            setPagination(res.data.paginationData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching items:', error);
            setLoading(false);
        }
    };
    const columnDefs = [
        { headerCheckboxSelection: true, checkboxSelection: true, headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, width: 50 },
        { headerName: 'User Name', field: 'userNo', filter: true, floatingfilter: true, suppressMovable: true, width: 220 },
        { headerName: 'User Role', field: 'itemDescription', filter: true, floatingfilter: true, suppressMovable: true, width: 220 },
        { headerName: 'Email', field: 'userNo', filter: true, floatingfilter: true, suppressMovable: true, width: 220 },
        { headerName: 'Doc Type', field: 'docType', filter: true, floatingfilter: true, suppressMovable: true, width: 220 },
        { headerName: 'Department', field: 'department', filter: true, floatingfilter: true, suppressMovable: true, width: 220 },
        { headerName: 'Approval  Level', field: 'userLevel', filter: true, floatingFilter: false },
        {
            headerName: 'Status', field: 'active', filter: true, floatingfilter: true, suppressMovable: true, width: 180,
            cellRenderer: function (params) {
                if (params.value === 0 || params.value === null) {
                    return (<button class="btn pend ">In Active</button>);
                }
                else if (params.value === 1 || params.value === null) {
                    return (<button class="btn aproved">Active</button>);
                } else {
                    return params.value;
                }
            }
        },
    ];
    const onCellClicked = (event) => {
        if (event.colDef.field === 'itemNo') {
            const clickedRowData = event.data;
            setSelectedRow(clickedRowData);
            handleEye(clickedRowData)
        }
    };
    const handlenew = () => {
        navigate('/Sales/ApprovalUser/Create');
    };
    return (
        <div className='px-4 pt-3'>
            {loading ? (
                <div className='center'>
                    <PropagateLoader color="#6E5199" size={15} />
                </div>
            ) : (
                <div className='gridBg mt-3'>
                    <div className='justify-content-between d-flex align-items-center pb-2 border-0'>
                        <h5 className='col mainInvHead'>Approval User List</h5>
                        <div className='d-flex gap-1'>
                            {permissions?.canUpdate === true ?
                                <div
                                    onClick={handleEdit}
                                    className={`mainbtnSecondary flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                    style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                                >
                                    <GrEdit color='#fff' className='center mt-1 mx-2' />
                                    <div className='center'>Edit</div>
                                </div> : ""}
                            <div
                                onClick={handleEye}
                                className={`mainbtnGry flex-end gap-2 ${!selectedRow ? 'disabled' : ''}`}
                                style={{ cursor: selectedRow ? 'pointer' : 'not-allowed' }}
                            >
                                <FaRegEye color='#fff' className='center mt-1' />
                                <div className='center'>View</div>
                            </div>
                            {permissions?.canAdd === true ?
                                <div onClick={handlenew} className='mainbtn flex-end gap-1'>
                                    <IoAddSharp color='#fff' className='center mt-1 mx-1' size={18} />
                                    <div className='center'>Add</div>
                                </div> : ""}
                        </div>
                    </div>
                    <div className="gridshad">
                        <div className="ag-theme-quartz" style={{ height: 570 }}>
                            <AgGridReact
                                getRowStyle={(params) => {
                                    return params.node.rowIndex % 2 === 0
                                        ? { background: '#F0EDF5' }
                                        : { background: '#fff' };
                                }}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                pagination={false}
                                onSelectionChanged={onSelectionChanged}
                                onCellClicked={onCellClicked}
                            />
                        </div>
                    </div>
                    <Pagination setPageSize={setPageSize} pagination={pagination} getItems={getItems} />
                </div>
            )}
        </div>
    );
};

export default ApprovalListing;